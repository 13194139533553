"use client";

import Link from "next/link";
import H2 from "../common/H2";
import { useEffect, useRef } from "react";
import TransitionUp from "../common/TransitionUp";

export default function Footer() {
  const ref = useRef<HTMLDivElement>(null);
  // add intersection observer to add class to body when footer is fully in view
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        document.body.classList.toggle("footer-in-view", entry.isIntersecting);
      });
    });

    observer.observe(ref.current!);
  }, []);

  return (
    <footer
      ref={ref}
      className="col-full bg-black min-h-[100vh] text-white flex flex-col justify-end pb-[150px]"
    >
      <div className="grid-container grid-padded">
        <H2
          className="col-start-1 col-end-6 text-white"
          end="bottom bottom+=-150px"
        >
          Let's Talk
        </H2>

        <TransitionUp
          delay={0.25}
          end="bottom bottom+=-150px"
          as="div"
          className="col-start-7 col-end-13 stack gap-8"
        >
          <p>
            I'm always interested in exploring new challenges with people who
            want to push boundaries. Whether you've got a specific problem to
            solve or just want to talk about what's possible with technology,
            find me on{" "}
            <Link
              href="https://www.linkedin.com/in/baird-lackner-buckingham"
              target="_blank"
            >
              LinkedIn
            </Link>{" "}
            for a chat. And if you're looking to bring an ambitious digital
            project to life, let's connect through{" "}
            <Link href="https://planetpropaganda.com" target="_blank">
              Planet Propaganda
            </Link>
            .
          </p>
          <div className="flex gap-8 items-end">
            <Link
              href="https://www.linkedin.com/in/baird-lackner-buckingham"
              target="_blank"
              className="text-white"
            >
              <svg
                height="35"
                viewBox="0 0 60 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9664 18.9576H1.92136C1.38677 18.9576 0.953613 19.3847 0.953613 19.9114V58.0461C0.953613 58.5729 1.38677 58.9998 1.92136 58.9998H13.9664C14.501 58.9998 14.9342 58.5729 14.9342 58.0461V19.9114C14.9342 19.3847 14.501 18.9576 13.9664 18.9576Z"
                  fill="currentColor"
                />
                <path
                  d="M7.94826 0C3.56555 0 0 3.51004 0 7.82444C0 12.1407 3.56555 15.6521 7.94826 15.6521C12.3275 15.6521 15.8901 12.1405 15.8901 7.82444C15.8903 3.51004 12.3275 0 7.94826 0Z"
                  fill="currentColor"
                />
                <path
                  d="M44.604 18.0099C39.7662 18.0099 36.19 20.0594 34.0209 22.3882V19.9114C34.0209 19.3848 33.5878 18.9577 33.0532 18.9577H21.5179C20.9833 18.9577 20.5502 19.3848 20.5502 19.9114V58.0461C20.5502 58.5729 20.9833 58.9998 21.5179 58.9998H33.5367C34.0713 58.9998 34.5044 58.5729 34.5044 58.0461V39.1783C34.5044 32.8203 36.2568 30.3433 40.7541 30.3433C45.652 30.3433 46.0413 34.3141 46.0413 39.5054V58.0463C46.0413 58.5731 46.4744 59 47.009 59H59.0322C59.5668 59 60 58.5731 60 58.0463V37.1287C60 27.6746 58.1707 18.0099 44.604 18.0099Z"
                  fill="currentColor"
                />
              </svg>
            </Link>

            <Link href="https://planetpropaganda.com" target="_blank">
              <svg
                height="30"
                viewBox="0 0 44 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-white"
              >
                <path
                  d="M26.3013 36.3722C39.559 36.3722 43.1604 29.7234 43.1604 17.9215C43.1604 4.65948 36.7349 0.789307 25.524 0.789307H0V47.4227H15.9177L15.9249 13.5835H22.4075C26.1516 13.5835 27.856 15.3485 27.856 18.524C27.856 21.6996 25.8235 23.4716 22.4075 23.4716H20.6103V36.3722H26.3013Z"
                  fill="currentColor"
                />
              </svg>
            </Link>
          </div>
        </TransitionUp>
      </div>
    </footer>
  );
}
