"use client";

import { useRef, ReactNode } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/ScrollTrigger";
import clsx from "clsx";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

export default function H2({
  children,
  end = "top 20%",
  className,
}: {
  children: ReactNode;
  className?: string;
  end?: string;
}) {
  const heading = useRef(null);
  const main = useRef();

  useGSAP(
    () => {
      gsap.to(heading.current, {
        translateY: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: heading.current,
          start: "top bottom-=150px",
          end: end,
          scrub: true,
          // markers: true,
        },
      });
    },
    { scope: main }
  );
  return (
    <h2
      className={clsx("font-black uppercase text-h1", className)}
      ref={heading}
      style={{ opacity: 0, transform: "translateY(100px)" }}
    >
      {children}
    </h2>
  );
}
