"use client";

import { useRef, ReactNode } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/ScrollTrigger";
import clsx from "clsx";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

export default function H2({
  children,
  className = "",
  end = "top 20%",
  as = "div",
  delay = 1,
}: {
  children: ReactNode;
  className?: string;
  as?: string;
  end?: string;
  delay?: number;
}) {
  const heading = useRef(null);
  const main = useRef();

  const Component = as;

  useGSAP(
    () => {
      gsap.to(heading.current, {
        translateY: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: heading.current,
          start: "top bottom",
          end: end,
          scrub: delay,
          // markers: true,
        },
      });
    },
    { scope: main }
  );
  return (
    <Component
      className={clsx(className)}
      ref={heading}
      style={{ opacity: 0, transform: "translateY(50px)" }}
    >
      {children}
    </Component>
  );
}
