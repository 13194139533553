"use client";

import { useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

export default function Project({
  imageSide,
  imageSrc,
  title,
  subtitle,
  description,
  builtFor,
}: {
  imageSide?: "left" | "right";
  imageSrc: string;
  title: string;
  subtitle: string;
  description: string;
  builtFor: string;
}) {
  const container = useRef(null);
  const image = useRef(null);
  const heading = useRef(null);
  const text = useRef(null);
  const main = useRef();

  useGSAP(
    () => {
      if (!container.current) return;
      gsap.to(image.current, {
        translateX: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: container.current,
          start: "top+=150 bottom",
          end: "bottom bottom-=150",
          scrub: true,
          // markers: true,
        },
      });

      gsap.to(text.current, {
        translateX: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: container.current,
          start: "top+=150 bottom",
          end: "bottom bottom-=150",
          scrub: true,
          // markers: true,
        },
      });

      gsap.to(heading.current, {
        translateX: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: container.current,
          start: "top+=150 bottom",
          end: "bottom bottom-=150",
          scrub: true,
          // markers: true,
        },
      });
    },
    { dependencies: [image, text, heading], scope: main.current }
  );

  return (
    <section
      className="grid-container grid-padded gap-12 lg:gap-24 overflow-hidden h-max"
      ref={container}
    >
      {imageSide === "right" && (
        <>
          <h2
            className={`uppercase col-full flex ${
              imageSide === "left" ? "justify-end" : ""
            }`}
            ref={heading}
            style={{ opacity: 0, transform: "translateX(-100px)" }}
          >
            {title}
          </h2>
          {subtitle && <h3 className="text-gray">{subtitle}</h3>}
          <div className="col-start-1 col-end-13 grid-container gap-32 relative w-full">
            <div
              className="stack gap-8 col-start-1 col-span-2 lg:col-end-5 lg:row-start-1 z-[1]"
              ref={text}
              style={{ opacity: 0, transform: "translateX(-50px)" }}
            >
              {description}
              <p className="text-[1rem] flex gap-12 items-start text-gray">
                <span className="font-bold text-gray max-w-[80px] min-w-[80px]">
                  Built for
                </span>{" "}
                {builtFor}
              </p>
            </div>
            <div className="col-start-5 col-end-13 lg:row-start-1 z-[0]">
              <img
                src={imageSrc}
                alt=""
                className="w-full object-cover relative block rounded-[1rem] overflow-hidden translate-x-[150px] opacity-0 aspect-square lg:aspect-auto h-[600px] max-h-[100dvh]"
                width={1200}
                height={800}
                loading="lazy"
                ref={image}
              />
            </div>
          </div>
        </>
      )}
      {imageSide === "left" && (
        <>
          <h2
            className="uppercase col-full"
            ref={heading}
            style={{ opacity: 0, transform: "translateX(-100px)" }}
          >
            {title}
          </h2>
          <div className="col-start-1 col-end-13 grid-container gap-24 relative w-full">
            <div className="col-start-1 col-span-2 lg:col-end-8 lg:row-start-1 z-[0]">
              <img
                src={imageSrc}
                alt=""
                className="w-full object-cover relative block rounded-[1rem] overflow-hidden  translate-x-[-150px] opacity-0 aspect-square lg:aspect-auto h-[600px] max-h-[100dvh]"
                width={1200}
                height={800}
                loading="lazy"
                ref={image}
              />
            </div>
            <div
              className="stack gap-8 col-start-8 col-end-13 lg:row-start-1 z-[1]"
              ref={text}
              style={{ opacity: 0, transform: "translateX(50px)" }}
            >
              {description}
              <p className="text-[1rem] flex gap-12 items-start text-gray">
                <span className="font-bold text-gray max-w-[80px] min-w-[80px]">
                  Built for
                </span>{" "}
                {builtFor}
              </p>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
