"use client";
import clsx from "clsx";
import useFitText from "use-fit-text";

export default function H1({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return <h1 className={clsx(className)}>{children}</h1>;
}
